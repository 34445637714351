.reward-con {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  background: url('../../../assets/images/reward_bg.png') no-repeat;
  background-size: cover;
  > .join-team {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    background: #E5376C;
    color: #FFFFFF;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 74%;
    left: 20px;
    z-index: 200;
    .team-btn {
      width: 40px;
      text-align: center;
      position: relative;
    }
    .team-btn-two {
      @extend .team-btn;
      .team-btn-time {
        position: absolute;
        width: 70px;
        height: 20px;
        border-radius: 12px;
        background: #FFFFFF;
        bottom: -20px;
        left: 50%;
        margin-left: -35px;
      }
    }
  }
  >.music-icon {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 38px;
    color: #FFFFFF;
  }
  .spin {
    animation: spin 1s steps(8) infinite;
  }
  @keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }
  >.reward-top-text {
    width: 90%;
    height: 50px;
    img {
      width: 100%;
    }
  }
  .reward-main {
    margin-top: 100px;
  }
  .reward-img {
    width: 260px;
    height: 250px;
    margin: 80px auto 20px;
    img {
      width: 100%;
    }
  }
  .reward-text-info {
    width: 230px;
    display: flex;
    justify-items: center;
    flex-direction: column;
    color: #FFFFFF;
    margin: 0 auto;
    align-items: center;
    > .reward-text-title {
      font-size: 18px;
      font-weight: bold;
      color: #dcae6f;
    }
    > .reward-text-goods {
      letter-spacing: 2px;
      font-size: 16px;
      margin-top: 10px;
    }
  }
  .reward-btn {
    width: 230px;
    height: 40px;
    color: #FFFFFF;
    text-align: center;
    line-height: 40px;
    background: #dcae6f;
    margin: 30px auto;
    border-radius: 20px;
  }
  .reward-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    color: #fff;
  }
  .reward-info {
    width: 100%;
    height: auto;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    background-color: #fff;
    padding-bottom: 20px;
    > .reward-goods {
      width: 100%;
      padding: 10px;
      .award-text {
        flex: 1;
        color: #393939;
        display: flex;
        .award-name {
          width: 50px;
          max-width: 94px;
          overflow: hidden;
          margin-right: 10px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      > .reward-title {
        font-size: 20px;
        color: #d91c1c;
        font-weight: bold;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 8px;
          height: 20px;
          background: #d91c1c;
          left: -20px;
          top: 4px;
        }
      }
      > .reward-list {
        width: 100%;
        max-height: 100px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        > .my-goods {
          flex: 1;
          display: flex;
          justify-content: space-between;
          > .show-detail {
            color: #d91c1c;
          }
        }
      }
      > .reward-row {
        width: 100%;
        > .reward-items {
          display: flex;
          align-items: center;
          color: #393939;
          margin: 10px 0;
          justify-content: flex-start;
          > .reward-left {
            width: 73px;
            height: 60px;
            border-radius: 3px;
            border: 1px solid #dfdfdf;
            img {
              width: 100%;
            }
          }
          > .reward-right {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            span:nth-child(1) {
              font-size: 14px;
              color: rgb(41, 41, 41);
            }
          }
        }
      }
    }

  }
  .reward-bottom {
    width: 100%;
    height: 50px;
    color: #ffffff;
    position: fixed;
    left: 0;
    bottom: 0;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    background-color: #d91c1c;
  }
}

/**活动详情**/

.detail-all {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  background-color: #902d40;
  .detail-center {
    width: 100%;
    height: auto;
    margin-top: 90px;
    border-radius: 12px;
    background-color: #fff;
    display: flex;
    padding-bottom: 30px;
    align-items: center;
    flex-direction: column;
    >.detail-title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      margin-top: 40px;
    }
    >.detail-qx {
      width: 50%;
      color: #393939;
      margin-top: 10px;
      text-align: center;
    }
    >.detail-dj {
      font-size: 16px;
      color: #e84465;
      font-weight: bold;
      margin-top: 10px;
    }
    .detail-info {
      margin-top: 10px;
      font-size: 12px;
      color: #333333;
    }
    .detail-bot {
      width: 100%;
      border-top: 1px dashed #dfdfdf;
      margin-top: 19px;
      padding: 0 15px;
      position: relative;
      >.detail-d {
        width: 10px;
        height: 10px;
        background: #902d40;
        border-radius: 5px;
        position: absolute;
        left: -5px;
        top: -5px;
      }
      >.detail-d-2 {
        @extend .detail-d;
        left: 350.5px;
      }
      >.detail-b-text {
        margin-top: 16px;
        font-size: 16px;
      }
      >.detail-qx-date {
        width: 80%;
        display: flex;
        margin-top: 10px;
        color: #333333;
        span:nth-child(2) {
          margin-left: 8px;
        }
      }
    }
  }
}

.team-con {
  width: 90%;
  max-height: 400px;
  background: #FFFFFF;
  margin: 120px auto;
  border-radius: 12px;
  padding: 20px;
  > .close-icon {
    font-size: 24px;
    font-weight: bold;
    float: right;
  }
  > .team-com-title {
    width: 100%;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 560;
    text-align: center;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 10px;
    padding-left: 26px;
  }
  > .team-com-img {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    font-size: 20px;
    div {
      width: 60px;
      height: 60px;
      background: #d09a9a;
      border-radius: 30px;
    }
  }
  > .team-com-people {
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
  }
  > .team-com-info {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    color: #393939;
  }
  > .team-com-friend {
    width: 280px;
    height: 40px;
    color: #FFFFFF;
    background: #D83A56;
    border-radius: 18px;
    margin: 30px auto;
    text-align: center;
    line-height: 40px;
    letter-spacing: 2px;
    font-weight: 560;
  }
  > .team-com-friend-un {
    @extend .team-com-friend;
    background: #c8c5c5;
    color: #FFFFFF;
  }
  > .team-com-span {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }
  .pub-color {
    color: #DA0037;
  }
}