.info_text {
  height: 30px;
  line-height: 30px;
  padding-left: 14px;
  background: rgba(242,243,245, 1);

}
.forget_text {
  display: flex;
  width: 100%;
  padding: 0 18px;
  color: #2B2C36;
  text-decoration: underline;
  justify-content: space-between;
  background: rgba(242,243,245, 1);
}
