.loading_con {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: #ffffff;
  z-index: 1000;
  overflow: hidden;
  > .loadPage-anim {
    width: 100%;
    height: 160px;
    position: relative;
    margin-top: 250px;
    > .flexDiv {
      width: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-line-pack: center;
      align-content: center;
      -ms-flex-align: center;
      align-items: center;
      > .loadPage_mountain {
        width: 100%;
        height: 66.8px;
      }
    }
    .moyntainAni {
      width: 100%;
      min-height: 140px;
      position: absolute;
      bottom: 50px;
      left: 0;
      z-index: 4;
      animation: moyntainAni 5.4s ease-in-out infinite both;
      > .loadPage_mountain {
        width: 100%
      }
    }
  }

}

.loading_car {
  width: 75px;
  height: 35px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 8;
  animation: carAni 5.4s ease-in-out infinite both
}
.loading_state {
  width: 380px;
  height: 190px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
  z-index: 6;
}

.mask-box {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  overflow: hidden;
  background: rgba(0,0,0,.8);
  display: flex;
  flex-direction: column;
  transition: all .5s linear;
  .close-icon-all {
    width: 100%;
    font-size: 30px;
    color: #FFFFFF;
    text-align: center;
  }
}



@keyframes carAni {
  0% {
    left: -80px
  }

  30%,
  48% {
    left: 37%
  }

  to {
    left: 104%
  }
}

@keyframes moyntainAni {
  0% {
    left: 0
  }

  30%,
  48% {
    left: -68%
  }

  to {
    left: -100%
  }
}