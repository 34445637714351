.check-phone {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FFFFFF;
  overflow: hidden;
  margin: 0 auto;
  > .am-list-body {
    position: relative;
    .make-btn {
      width: 100%;
      padding: 0 18px;
    }
    .send-code {
      position: absolute;
      width: 80px;
      height: 30px;
      font-size: 14px;
      left: 248px;
      top: 50px;
      line-height: 30px;
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      border-radius: 15px;
    }
    > .am-list-item {
      > .am-list-line {
        > .am-input-label-5 {
          width: 8.6vw;
          font-size: 20px;
          position: relative;
        }
      }
    }
    &:after {
      display: none;
      background: none;
    }
    &:before {
      display: none;
      background: none;
    }
  }
  > .list-sty {
    background: none;
  }
}

.pwd-con {
  width: 100%;
  height: 100vh;
  background: #fff;
  .pwd-title {
    font-size: 16px;
    font-weight: 560;
    color: #080808;
    letter-spacing: 2px;
  }
  .make-btn {
    width: 100%;
    padding: 0 18px;
    margin-top:15px;
  }
}