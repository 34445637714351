.lottery-info {
  width: 100%;
  display: flex;
  height: auto;
  align-items: center;
  flex-direction: column;
  background: #22222d;
  .lottery-top {
    width: 100%;
    height: 200px;
    background: url("../../../assets/images/bp_01.png") no-repeat 100%/100%;
    background-size: cover;
  }
  .lottery-main {
    width: 100%;
    padding: 0 0 20px;
    background: #FFFFFF;
    >.get-btn {
      width: 96%;
      margin: 0 auto;
      border: none;
      background: #e34137;
    }
  }

  .lottery-bot {
    width: 100%;
    height: 350px;
    background: url("../../../assets/images/bp-02.png") no-repeat 100%/100%;
    background-size: cover;
  }
}