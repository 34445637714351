.special-con {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .special-con-top {
    width: 100%;
    height: auto;
    background-color: #fff;
    display: flex;
    padding: 10px 4px 5px 10px;
    flex-direction: column;
    font-size: 12px;
    color: #9091a0;
    span:nth-child(1) {
      font-size: 18px;
      font-weight: 560;
      color: #2a2b2c;
    }
  }
  .special-upload {
    padding: 10px;
    background-color: #fff;
    .special-img {
      width: 100%;
      margin-top: 10px;
      img {
        width: 100%;
      }
    }
    .change-img {
      width: 100%;
      margin-top: 10px;
      text-align: right;
      line-height: 50px;
      border-bottom: 1px solid #dfdfdf;
    }
  }
  .upload-btn {
    width: 100%;
    display: flex;
    margin: 20px auto;
    > .am-button {
      width: 50%;
      border-radius: 0;
    }
  }
  .special-main {
    width: 100%;
    height: auto;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .special-item-title {
      width: 100%;
      padding-left: 10px;
      text-align: left;
      font-weight: bold;
    }
    .special-car-item {
      width: 96%;
      border-radius: 9px;
      margin-top: 10px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      .special-car-left {
        display: flex;
        flex-direction: column;
      }
      > .special-car-img {
        width: 60px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .activity {
      @extend .special-car-item;
      border: 1px solid #ff002f;
      box-sizing: border-box;
    }
  }
  .special-btn {
    width: 96%;
    margin: 20px auto;
  }
}


/****详情样式****/
.detail-con {
  width: 94%;
  height: 150px;
  margin: 10px auto;
  border-radius: 9px;
  background-color: #fff;
  img {
    width: 100%;
    height: 100%;
  }
}
.detail-center {
  width: 94%;
  height: auto;
  border-radius: 9px;
  margin: 0 auto;
  background-color: #fff;
}
.change-btn {
  width: 96%;
  margin: 20px auto;
}