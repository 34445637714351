.recharge-info {
  width: 89%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  letter-spacing: 2px;
  border-radius: 6px;
  border: 1px solid #dfdfdf;
}