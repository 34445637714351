.cooperate-con {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  padding: 0 20px;
  .cooperate-title {
    width: 100%;
    height: 60px;
    color: #4a4a4a;
    font-weight: 500;
    line-height: 60px;
  }
  .about-more {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    a {
      color: #393939;
      font-size: 14px;
    }
    .learn-more {
      display: flex;
      flex-direction: column;
      align-items: center;
      letter-spacing: 2px;
    }
    span:nth-child(2) {
      width: 118px;
      color: #999999;
      font-size: 12px;
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .cooperate-main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    .cooperate-item {
      width: 100%;
      height: 100px;
      padding: 10px;
      margin-top: 10px;
      border-radius: 9px;
      border: 1px solid #07BD13;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cooperate-left {
        width: 60px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      > .cooperate-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;
        span:nth-child(1) {
          font-weight: 500;
          color: #1AAD19;
          font-size: 15px;
        }
        span:nth-child(2) {
          color: #999999;
          font-size: 13px;
          margin-top: 8px;
        }
      }
    }
  }
}

.write-info {
  width: 100%;
  height: auto;
  background-color: #fff;
  padding-bottom: 4px;
  .push-btn {
    width: 90%;
    margin: 20px auto;
  }
}