.person-con {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  > .person-info-top {
    width: 100%;
    height: 140px;
    background-color: #e2a9a9;
  }
  > .person-info-fun {
    width: 100%;
    margin-top: 10px;
    min-height: 300px;
    background-color: #fff;
  }
}