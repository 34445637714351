.activity-home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .activity-top {
    width: 100%;
    height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../../assets/images/issue_bg.png') no-repeat 100%/100%;
    background-size: cover;
    video {
      height: 210px;
      max-width: 100%;
    }
  }
  .activity-text {
    width: 96%;
    padding: 6px;
    margin-top: 10px;
    border-radius: 12px;
    background-color: #fff;
  }
  .activity-detail {
    width: 96%;
    height: auto;
    display: flex;
    //padding: 10px 8px;
    margin: 10px auto;
    flex-direction: column;
    .activity-coupon {
      width: 100%;
      height: 120px;
      background: url("../../assets/images/coupon_bg.png") no-repeat 100%/100%;
      background-size: cover;
      position: relative;
      display: flex;
      margin-top: 10px;
      align-items: center;
      .coupon-money {
        font-size: 34px;
        font-weight: bold;
        color: #fff;
        margin-left: 20px;
        &:after {
          content: '元';
          color: #FFFFFF;
          font-size: 18px;
        }
      }
      .coupon-text-info {
        min-width: 90px;
        height: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: url("../../assets/images/cou_yjjx.png") no-repeat 100%/100%;
        background-size: cover;
        color: #FC403F;
        padding: 0 10px;
        margin-top: 18px;
        word-break: normal;
        text-align: left;
        margin-left: 12px;
      }
      > .coupon-right {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        position: absolute;
        right: 30px;
        > img {
          width: 100%;
        }
        > span {
          position: absolute;
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
          top: 16px;
          left: 11px;
        }
      }
    }
  }
  .activity-push {
    width: 100%;
    position: fixed;
    bottom: 110px;
    display: flex;
    padding: 24px 20px;
    margin-top: 10px;
    justify-content: space-between;
    background-color: #fff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-top: 1px solid #dfdfdf;
    .am-button-primary {
      width: 100%;
      height: 44px;
      border-radius: 34px;
      background-color: #ed7366;
      color: #fff;
      font-size: 16px;
      letter-spacing: 2px;
      border: none;
      &:before {
        border: none;
      }
    }
  }
  .activity-bottom {
    width: 100%;
    height: 130px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
  }
}

