
.zltc-info {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #fff;
  span {
    color: #4a4a4a;
  }
}
.oil-con-list {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .oil-btn-con {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    > .oil-btn {
      width: 100px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding: 10px 0;
      background: rgba(250,220, 163, .5);
      border-radius: 6px;
      margin: 5px;
      > span {
        line-height: 15px;
        color: #D98128;
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #D98128;
        text-align: center;
      }
    }
  }
}

.add-oil-con {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-bottom: 150px;
  .oil-center {
    flex-grow: 1;
    .store-oil {
      width: 100%;
      background-color: #fff;
      //padding: 10px 0;
      > .store-title {
        font-weight: 350;
        padding: 0 20px;
        line-height: 40px;
        font-size: 14px;
        color: #2B2C36;
      }
      > .oil-con {
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        padding: 0 18px;
        display: flex;
      }
    }
    .store-info {
      width: 100%;
      height: 100px;
      display: flex;
      padding: 0 10px;
      align-items: center;
      background-color: #fff;
      overflow: hidden;
      > .store-img {
        width: 54px;
        height: 54px;
        border-radius: 27px;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      > .store-main {
        flex: 1;
        display: flex;
        height: 60px;
        margin-left: 18px;
        justify-content: space-between;
        flex-direction: column;
        > h6 {
          font-size: 14.8px;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}


.oil-person {
  width: 100%;
  padding: 0 20px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 350;
  height: 30px;
  > span:nth-child(2) {
    margin: 0 5px;
    color: red;
  }
}

.oil-bottom {
  width: 100%;
  height: 100px;
  padding: 0 20px;
  display: flex;
  margin-top: 20px;
  color: #f58634;
  position: fixed;
  left: 0;
  bottom: 130px;
  align-items: center;
  background: rgba(250,220, 163, .5);
  > .oil-info-icon {
    margin-top: -40px;
  }
  > .oil-info {
    margin-left: 5px;
    > span:nth-child(1) {
      font-size: 14px;
      font-weight: 500;
    }
    > div {
      > .oil-info-1 {
        color: #ff5b05;
        margin: 0 5px;
        font-weight: 500;
      }
    }
  }
}

/*******用户支付**********/

.oil-all {
  width: 350px;
  height: 100vh;
  overflow-y: auto;
  padding-top: 14px;
  padding-bottom: 80px;
  margin: 0 auto;
  > .oil-all-init {
    height: 100px;
    display: flex;
    align-items: center;
    background-image: linear-gradient(228deg, #FBC534 0%, #FF9F24 100%);
    border-radius: 12px;
    padding: 0 20px;
    color: #FFFFFF;
    justify-content: space-between;
    margin-bottom: 8px;
    > .oil-all-left {
      display: flex;
      flex-direction: column;
      span:nth-child(1) {
        font-size: 18px;
        font-weight: 500;
      }
      span:nth-child(2) {
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
      }
    }
    > .oil-all-right {
      font-size: 16px;
    }
  }
  > .oil-all-top {
    height: auto;
    display: flex;
    align-items: center;
    border-radius: 3px;
    justify-content: space-between;
    margin-bottom: 8px;
    background: #FFFFFF;
    color: #2a2b2c;
    padding: 10px;
    > .oil-all-left {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span:nth-child(1) {
        font-size: 18px;
        font-weight: 560;
      }
      span:nth-child(2) {
        font-size: 16px;
        color: #dcae6f;
      }
    }
    > .am-button {
      &:before {
        display: none;
      }
    }
    > .choose-oil {
      width: 80px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F7A032;
      border-radius: 14.5px;
      border: none;
      overflow: hidden;
      span {
        font-size: 16px;
        margin-left: 5px;
        color: #FFFFFF;
      }
    }
    > .choose-oil-active {
      background: #ea0606;
      span {
        color: #FFFFFF;
      }
    }
  }
  .dis-header {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 34px;
    background-color: #fff;
    padding-left: 10px;
    margin-bottom: 10px;
    font-weight: 560;
    border-radius: 9px;
    border: 1px solid #0e80d2;
    > .dis-left {
      flex: 1;
      font-weight: 560;
      font-size: 36px;
    }
    > .dis-right {
      min-width: 100px;
      height: 100%;
      display: flex;
      font-size: 15px;
      background: #E8F0F2;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      justify-content: space-around;
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;
      > .oil-name {
        display: flex;
        font-weight: 560;
        flex-direction: column;
      }
    }
  }
  > .input-all {
    width: 100%;
    max-height: 135px;
    margin-top: 20px;
    background: #FFFFFF;
    border-radius: 6px;
    .am-input-label {
      width: 20px !important;
    }
    .money-icon {
      color: #D98128;
      font-size: 24px;
    }
    .fake-input-container {
      .fake-input {
        font-size: 24px !important;
        color: #D98128 !important;
      }
    }
    .am-list-body {
      &:after {
        display: none;
      }
      .am-list-content {
        font-size: 16px;
        color: #2B2C36;
      }
      .am-list-extra {
        color: #2B2C36;
      }
    }
    .oil-icon {
      font-size: 20px;
    }
  }

  /**
  零费率
   */
  .zero-con {
    width: 100%;
    min-height: 70px;
    padding: 8px 8px;
    margin: 0px auto 10px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    //border-radius: 12px;
    .zero-con-item {
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      > span:nth-child(2) {
        font-size: 12px;
      }
      .item-all {
        display: flex;
        align-items: center;
        .icon-vip {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #f3f5eb;
          opacity: .8;
          color: #e08244;
          text-align: center;
          line-height: 20px;
          font-weight: 500;
          font-size: 10px;
        }
        .icon-vip-fu {
          @extend .icon-vip;
          color: #558bd6;
        }
        .item-title {
          font-size: 13px;
          font-weight: 500;
          color: #47464a;
          margin-left: 6px;
          margin-right: 6px;
        }
      }
    }
  }
  .pay-btn {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    z-index: 200;
    .bottom-balance {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 10px;
    }
    .dis-money {
      color: #fc7035;
      span {
        margin-left: 5px;
      }
    }
    .more-btn {
      flex: 1;
      height: 100%;
      font-size: 16px;
      text-align: center;
    }
    .sun-all {
      display: flex;
      height: 100%;
      align-items: center;
      margin-left: 5px;
      .sum-text {
        margin-right: 7px;
        font-size: 14px;
        color: #2B2C36;
        display: block;
      }
      .sum-money {
        display: block;
        font-size: 24px;
        color: #2B2C36;
        font-weight: 500;
        margin-right: 20px;
      }
    }
    .get-money {
      width: 124px;
      height: 60px;
      font-size: 18px;
      color: #FFFFFF;
      border-radius: 0;
      line-height: 60px;
      background-image: linear-gradient(225deg, #F78338 0%, #FCB03A 100%);
      span:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
  .dis-info-con {
    .am-list-body {
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
    .card-list {
      max-width: 100%;
      height: 80px;
      display: flex;
      margin: 10px auto;
      border-radius: 3px;
      padding: 10px 16px;
      justify-content: flex-start;
      background-color: #FFFFFF;
      overflow-x: auto;
      .card-list-item {
        min-width: 110px;
        height: 100%;
        margin: 0 6px;
        padding: 6px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        background-color: #F7F7FA;
        span:nth-child(1) {
          font-size: 16px;
          color: #232323;
          font-weight: 560;
        }
        span:nth-child(2) {
          font-size: 14px;
          color: #FC5404;
          font-weight: 560;
        }
      }
      .active {
        @extend .card-list-item;
        background-color: #FFB600;
        span:nth-child(1) {
          color: #FFFFFF;
        }
        span:nth-child(2) {
          color: #FFFFFF;
        }
      }
    }
    .card-info {
      width: 100%;
      height: auto;
      padding: 10px;
      background-color: #fff;
    }
    .dis-info {
      width: 100%;
      height: 30px;
      font-size: 14px;
      color: #9091A0;
      line-height: 34px;
    }
    .am-list-header {
      padding: 0;
    }
    .pay-type {
      margin-top: 10px;
      .recharge-text {
        line-height: 30px;
        padding-left: 20px;
        font-size: 14px;
        color: #FFB31E;
        letter-spacing: 1px;
      }
      .recharge-list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: #fff;
        padding: 10px 0 10px 16px;
        overflow-x: scroll;
        .recharge-item {
          min-width: 140px;
          height: 60px;
          margin-left: 5px;
          background: #F7F7FA;
          display: flex;
          border-radius: 3px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          padding: 8px 10px;
          color: #2B2C36;
          font-weight: 560;
          letter-spacing: 2px;
          span:nth-child(2) {
            font-size: 14px;
          }
        }
        .recharge-item-active {
          @extend .recharge-item;
          color: #FFFFFF;
          background-color: #FFB600;
        }
      }
    }
    .pay-icon {
      font-size: 20px;
    }
    .dis-extra {
      display: flex;
      justify-content: flex-end;
      > span:nth-child(1) {
        margin-right: 10px;
        color: #2a2b2c;
        font-weight: bold;
      }
    }
    .text-str {
      position: absolute;
      font-size: 13.5px;
      color: #888888;
    }
    .check-dis-all {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text-dis {
        max-width: 208px;
        position: absolute;
        right: 40px;
        font-size: 12px;
        white-space:normal
        //white-space: nowrap;
        //overflow: hidden;
        //text-overflow: ellipsis;
      }
      .check-dis {
        color: #2B2C36;
        font-size: 14px;
        span:nth-child(1) {
          margin-right: 6px;
        }
      }
      .text-con {
        display: flex;
        flex-direction: column;
      }
    }

  }
  .card-show {
    width: 100%;
    min-height: 70px;
    padding: 8px 8px;
    margin: 10px auto;
    background: #FFFFFF;
    //border: 1px solid #C84B31;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
    > .card-title-info {
      font-size: 16px;
      margin-top: 5px;
      span:nth-child(2) {
        color: #ff002f;
      }
    }
    > .card-info-one {
      width: 100%;
      font-size: 12px;
      color: #333333;
      margin-top: 5px;
    }
    > .card-info-two {
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      margin-top: 6px;
      color: #393939;
    }
    > .card-show-main {
      width: 100%;
      min-height: 20px;
      display: flex;
      font-weight: 500;
      justify-content: space-between;
      position: relative;
      align-items: flex-end;
      margin-left: -5px;
      > .card-more {
        position: absolute;
        right: 10px;
      }
      > .card-show-right {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: #261d1d;
      }
      > .card-show-dis {
        max-width: 230px;
        //overflow: hidden;
        //text-overflow: ellipsis;
        font-weight: 400;
        display: flex;
        font-size: 14.5px;
        margin: 5px 0;
        flex-wrap: wrap;
        > .card-show-amount {
          min-width: 70px;
          min-height: 30px;
          padding: 2px 6px;
          background: #e35050;
          color: #FFFFFF;
          font-size: 16px;
          display: flex;
          align-items: center;
          border-radius: 6px;
          justify-content: center;
          margin-left: 5px;
          margin-top: 5px;
        }
      }
      > .card-show-title {
        padding: 0 10px;
        position: absolute;
        left: -10px;
        top: -8px;
        font-weight: bold;
        line-height: 28px;
        background: #f3680b;
        color: #FFFFFF;
        border-bottom-right-radius: 20px;
        border-top-left-radius: 12px;
        > .card-line {
          width: 238px;
          height: 2px;
          position: absolute;
          background: #161503;
          left: 108px;
          top: .6px;
        }
      }
    }
    > .card-show-main:nth-child(1) {
      font-weight: 300;
    }
    > .card-show-main:nth-child(2) {
      justify-content: space-between;
    }
  }
}
.radio-title {
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
}
.money-all {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  div {
    width: 70px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    color: #D98128;
    background: rgba(255, 220, 163, .5);
    border-radius: 6px;
  }
}

.keyboard-confirm {
  background-color: #ec4646;
}

tbody {
  .am-number-keyboard-item {
    height: 64px !important;
  }
  .keyboard-confirm {
    color: #FFFFFF;
    background-color: #1AAD19 !important;
  }
}

/**
优惠展示
 */
.dis-all-con {
  display: flex;
  flex-direction: column;
  .max-all-info {
    width: 100%;
    min-height: 50px;
    background-color: #fff;
    margin-bottom: 10px;
    .info-item {
      width: 100%;
      display: flex;
      padding: 0 14px;
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      justify-content: space-between;
      color: red;
      .logo-left {
        display: flex;
        align-items: center;
        .logo {
          width: 18px;
          height: 18px;
          margin-right: 6px;
        }
      }
    }
    .info-title {
      width: 100%;
      display: flex;
      padding: 0 14px;
      height: 30px;
      margin-top: 10px;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      font-weight: bold;
      span:nth-child(2) {
        font-size: 16px;
        color: red;
      }
    }
    .bot-info {
      width: 100%;
      height: 30px;
      border-top: 1px solid #dfdfdf;
      padding-right: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .real-money {
        margin-right: 5px;
      }
      .money-text {
        font-weight: bold;
        font-size: 18px;
        color: red;
      }
    }
  }
  .zero-con-title {
    width: 100%;
    height: 35px;
    display: flex;
    padding: 0 14px;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    .title-money {
      font-size: 16px;
      font-weight: bold;
    }
    .title-left {
      .title-info {
        font-size: 15px;
        font-weight: bold;
      }
      .title-text {
        margin-left: 5px;
        color: #4a4a4a;
        font-size: 12px;
      }
    }
  }
}



.dis-bot {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  >.dis-item {
    width: 100%;
    height: 30px;
    display: flex;
    padding: 0 10px;
    align-items: center;
    font-size: 12.6px;
    justify-content: space-between;
    border-top: 1px solid #dfdfdf;
  }
}