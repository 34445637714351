.top-header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}
.coupon-list {
  width: 100%;
  padding: 50px 10px;
  z-index: -1;
  .coupon-item {
    height: 80px;
    display: flex;
    align-items: center;
    margin: 10px 0;
    background-color: #fff;
    position: relative;
    > .coupon-left {
      width: 40px;
      height: 100%;
      text-align: center;
      color: #ffffff;
      font-size: 20px;
      line-height: 80px;
      background: #f54748;
    }
    > .coupon-center {
      display: flex;
      margin-left: 12px;
      flex-direction: column;
      span:nth-child(1) {
        font-size: 18px;
        color: #f54748;
        font-weight: 560;
      }
      span:nth-child(2) {
        margin-top: 6px;
        color: #4A4A4A;
        font-size: 14px;
      }
    }
    > .coupon-right {
      width: 100px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      > .coupon-code {
        width: 40px;
        height: 40px;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        border-radius: 20px;
        background: #000;
      }
      > .coupon-code-text {
        font-size: 12px;
        margin-top: 6px;
      }
    }
  }
}
