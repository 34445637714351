.login-con {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  > .login-banner {
    width: 100%;
    height: 333.5px;
    position: relative;
    background-color: #0136DE;
    > .login-title {
      position: absolute;
      top: 33.5px;
      left: 18px;
      font-size: 18px;
      color: #ffffff;
    }
    > img {
      width: 100%;
      height: 100%;
    }
  }
  > .login-main {
    width: 100%;
    position: relative;
    height: calc(100vh - 333.5px);
    background-color: #ffffff;
    > .login-input {
      width: 338px;
      height: 123px;
      display: flex;
      flex-direction: column;
      //align-items: center;
      justify-content: center;
      background: #FFFFFF;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.12);
      border-radius: 15px;
      overflow: hidden;
      margin: -62px auto 0;
      > .am-list-body {
        position: relative;
        > .send-code {
          position: absolute;
          width: 80px;
          height: 30px;
          font-size: 14px;
          left: 248px;
          top: 50px;
          line-height: 30px;
          background: #FFFFFF;
          border: 1px solid #CCCCCC;
          border-radius: 15px;
        }
        > .am-list-item {
          > .am-list-line {
            > .am-input-label-5 {
              width: 8.6vw;
              font-size: 20px;
              position: relative;
            }
          }
        }
        &:after {
          display: none;
          background: none;
        }
        &:before {
          display: none;
          background: none;
        }
      }
      > .list-sty {
        background: none;
      }
    }
    > .login-bot {
        width: 304px;
        margin: 45px auto 0;
      > .login-btn {
        border-radius: 22px;
        background-color: #0136DE;
      }
      > .sign-btn {
        border-radius: 22px;
        margin-top: 9px;
      }
      > .login-bot-text {
        display: flex;
        padding-top: 50px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > .bot-text-title {
          font-size: 14px;
          color: #262626;
        }
        > .bot-text-end {
          margin-top: 25px;
        }
      }
    }
  }
}