.pwd_con {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background: #f2f3f5;
  > .pwd_item {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 20px;
    line-height: 1.2;
    background-color: #fff;
    > .pwd_i {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12.5px;
      height: 12.5px;
      background-color: #000;
      border-radius: 100%;
      transform: translate(-50%, -50%);
    }
    > .pwd_line {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1px;
      height: 40%;
      background-color: #323233;
      transform: translate(-50%, -50%);
      animation: 1s van-cursor-flicker infinite;
    }
  }
  > .pwd_item::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    border: 0 solid #080808;
    transform: scale(0.5);
    border-left-width: 1px;
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  > .pwd_item:nth-last-child(1)::after {
    border-right-width: 1px;
  }
}


@keyframes van-cursor-flicker {
  0% {
    opacity: 0;
  } 
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
