
$bgcolor: #5d8dec;
$dis_text: #fd7a7a;
$oil_item: #FFDCA3;

page {
  padding-bottom: 70px;
}

.top-main {
  > .at-nav-bar {
    background: rgba(255, 255, 255, 0);
    > .at-nav-bar__title {
      color: #fff;
    }
  }
}

.emp_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  border-bottom: 1px solid rgba($color: #e5e6f6, $alpha: 0.97);
  > .oil_list_item {
    width: 94px;
    height: 64px;
    border-radius: 6px;
    display: flex;
    margin: 10px 12px;
    border: none;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba($color: $oil_item, $alpha: 0.5);
    > .oil_text {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #d98128;
      text-align: center;
      line-height: 22px;
    }
  }
  .active {
    @extend .oil_list_item;
    background: #ffb600;
    border-radius: 6px;
    text {
      color: #fff;
    }
  }
  .at-button__text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > .oil_text {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #d98128;
      text-align: center;
      line-height: 22px;
    }
  }
}
.card_con {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > .recharge-text {
    width: 100%;
    margin-top: 5px;
    text-align: center;
    color: #fe5655;
    font-weight: 560;
  }
  > .card_banner {
    width: 100%;
    height: 120px;
    background-color: #5d8dec;
    // background: url("https://zltc-1255680458.cos.ap-beijing.myqcloud.com/1611545122.png")
    //   no-repeat 100%/100%;
    .card_banner_top {
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-top: 25px;
      .card_banner_top_img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #fff;
        margin-right: 15px;
        overflow: hidden;
        image {
          width: 100%;
          height: 100%;
        }
      }
      .card_banner_info {
        color: #fff;
      }
    }
    > .yue_title {
      margin-top: 65px;
      margin-left: 49px;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
    }
    > .yue_money {
      width: 100%;
      height: 65px;
      display: flex;
      position: relative;
      align-items: center;
      overflow: hidden;
      justify-content: center;
      > .yue_btn {
        position: absolute;
        right: -25px;
        top: 5px;
        width: 72px;
        padding: 5px 10px;
        background: #fe5655;
        border-radius: 16px;
        color: #fff;
      }
      > .balance_show {
        color: #fff;
        font-weight: 500;
        font-size: 40px;
        &::before {
          content: "￥";
          font-size: 20px;
          margin-right: 5px;
        }
      }
    }
  }
  .card-type {
    width: 94%;
    min-height: 120px;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    margin: -25px auto 0;
    .recharge-list {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 10px;
      flex-wrap: wrap;
      .item-active {
        color: #fff;
        background-color: $bgcolor
      }
      .recharge-list-item {
        width: 32%;
        height: 60px;
        display: flex;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px solid #dfdfdf;
        margin-top: 10px;
        margin-left: 4px;
        text:nth-child(1) {
          font-size: 16px;
          font-weight: 600;
        }
        text:nth-child(2) {
          font-size: 10px;
          font-weight: 300;
          margin-top: 4px;
        }
      }
    }
  }
  > .card_list {
    width: 94%;
    height: auto;
    padding: 0 10px 10px;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    margin: 0px auto 10px;
    > .card_title {
      width: 100%;
      margin-top: 10px;
      font-size: 15px;
      font-weight: 560;
      color: #353535;
      margin: 15px 0;
      display: flex;
      justify-content: space-between;
    }
    .recharge-con {
      width: 100%;
      margin-top: -10px;
      display: flex;
      flex-wrap: wrap;
      > .save_money {
        width: 28%;
        height: 60px;
        display: flex;
        position: relative;
        align-items: center;
        flex-direction: column;
        line-height: 30px;
        border-radius: 7px;
        margin-left: 15px;
        margin-top: 10px;
        font-weight: 500;
        padding-top: 17px;
        background: #fff;
        color: #000000;
        border: 1px solid $bgcolor;
        overflow: hidden;
        > .save_tag {
          width: 62px;
          height: 20px;
          position: absolute;
          line-height: 20px;
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          left: -20px;
          top: 1px;
          transform: rotate(-50deg);
          text-align: center;
          background: #5d8dec;
        }
        > .charge_money_title {
          color: $bgcolor;
          font-size: 24px;
          color: #353535;
          &::after {
            content: "元";
            color: #353535;
            font-size: 14px;
            margin-left: 4px;
          }
        }
        > .charge_money_dis {
          color: $dis_text;
          font-size: 12px;
          margin-top: 7px;
        }
      }
      > .active {
        @extend .save_money;
        color: $bgcolor;
        background: #edf1fc;
        > .charge_money_title_active {
          @extend .charge_money_title;
          color: $bgcolor;
          &::after {
            color: $bgcolor;
          }
        }
      }
    }
  }
  .recharge-info {
    width: 90%;
    height: auto;
    border-radius: 15px;
    margin: 10px auto;
    color: #ffffff;
    background-color: $bgcolor;
    view {
      width: 90%;
      line-height: 40px;
      margin: 10px auto;
    }
  }
  > .bottom_btn {
    width: 340px;
    height: 50px;
    position: fixed;
    bottom: 20px;
    left: 50%;
    margin-left: -170px;
    > .bottom_btn_sty {
      border: none;
      border-radius: 48px;
      background: $bgcolor;
    }
  }
}
