.coupon_item_bottom {
  padding-top: 4px;
  margin-top: 3px;
  border-top: 1px solid #dfdfdf;
}
.coupon_item {
  width: 96%;
  height: auto;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 6px;
  padding: 6px 10px;
  box-shadow: 0 0 0.1px rgba(0,0,0,.1);
  border: 1px solid #f1f1f1;
  > .coupon_item_main {
    width: 100%;
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: flex-start;
    .coupon_item_type {
      width: 70px;
      height: 100px;
      display: flex;
      font-size: 23px;
      font-weight: 560;
      justify-content: center;
      align-items: center;
      background: #f4f4f4;
      border-radius: 12px 0 12px  0;
    }
    .coupon_item_type::after {
      content: '元';
      font-size: 14px;
      margin-left: 3px;
      margin-top: 10px;
    }
    > .coupon_item_center {
      flex: 1;
      margin-left: 5px;
      display: flex;
      align-items: center;
      padding: 6px;
      border-radius: 6px;
      justify-content: space-between;
      .coupon_item_card {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        > .coupon_item_title {
          font-size: 16px;
          font-weight: 560;
          color: #2a2b2c;
        }
        .coupon-item-stepper {
          .am-stepper {
            width: 120px;
            margin-top: 10px;
            .coupon-down, .coupon-up  {
              width: 20px;
              height: 20px;
            }
          }
        }
        > .coupon_item_exp_time {
          font-size: 14px;
          font-weight: 400;
          color: #666;
        }
      }
    }
  }
}
.no-used {
  width: 360px;
  position: absolute;
  left: 50%;
  margin-left: -180px;
}
.choose_used {
  > .init_con {
    width: 350px;
    height: auto;
    display: flex;
    margin: 8px auto;
    flex-direction: column;
    > .init_con_item {
      width: 100%;
      height: 43px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-size: 14px;
      color: #2B2C36;
      background-color: #fff;
      justify-content: space-between;
      border-bottom: 1px solid #E5E6F6;
      Radio {
        margin-left: 5px;
        z-index: -10;
      }
    }
  }
  > .title_dis {
    display: inline-block;
    font-size: 14px;
    color: #9091A0;
    line-height: 14px;
    margin-left: 16px;
    margin-top: 10px;
  }
}
.out_btn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: fixed;
  left: 0;
  bottom: 0px;
  font-size: 10px;
  color: #f05454;
  text-align: center;
  background-color: #fff;
  z-index: 20;
  border-top: 1px solid #dfdfdf;
}