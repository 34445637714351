.bottom-copyright {
  width: 100%;
  height: 100px;
  display: flex;
  // position: fixed;
  margin: 10px 0px 20px 0px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > .learn-more {
    width: 90px;
    padding: 4px 10px;
    background-color: #363636;
    margin-top: 6px;
    color: #ffffff;
  }
}