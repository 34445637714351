.icon-success {
  font-size: 60px;
}

.am-list-item .am-list-line .am-list-extra {
  flex-basis: 56%;
}
.spe {
  width: 60px;
  height: 60px;
}