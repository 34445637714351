.pwd_keyboard {
  background:#f2f3f5;
  overflow: hidden;
  padding: 4px 4.5px 7.5px 4.5px;
  display: flex;
  font-weight: bold;
  .pwd_line_part{
    flex: 1;
    margin: 1%;
    text-align: center;
    .pwd_key_item {
      height: 50px;
      line-height: 50px;
      margin-bottom: 7.5px;
      font-size: 22px;
      border-radius: 5px;
    }
    .pwd_key_item3x{
      height: 164px;
      line-height: 164px;
      margin-bottom: 7.5px;
      border-radius: 6px;
      background: #07BD13;      
      font-size: 22px;
      box-shadow: 2.5px 2.5px 2.5px 0px rgba(0,0,0,0.5);
      color: #fff;
    }
    .pwd_key_item3x:active{
      background: rgb(161, 223, 132);
      opacity: 0.8;
    }
  }
  .pwd_close_size {
    font-size: 20px;
    font-weight: 300;
  }
  .pwd_top_key{
    height: 50px;
    line-height: 50px;
    margin-bottom: 7.5px;
    border-radius: 6px;
    font-size: 22px;
    box-shadow: 2.5px 2.5px 2.5px 0px rgba(0,0,0,0.05);
    background: #1989fa;
    color: #fff;
  }
  .pwd_top_key:active{
    background: rgba(86,140,255,1);
    opacity: 0.7;
  }
}


.at-float-layout__container {
  min-height: 500px;
  .layout-header {
    padding-right: 0;
    text-align: center;
    background-color: #fff;
    font-size: 32px;
    color: #2B2C36;
    font-weight: 560;
  }
  .layout-body {
    padding: 0;
  }
}