.user-card-con {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  .user-card-main {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    > .user-card-title {
      font-size: 16px;
      font-weight: 560;
      color: #393939;
    }
    > .user-card-list {
      width: 100%;
      > .user-card-all {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        background: #FFF5EB;
        margin: 10px 0;
        border-radius: 9px;
        > .card-info-bot {
          padding-left: 10px;
          text-align: left;
          line-height: 30px;
          font-size: 14px;
          border-top: 1px solid #dfdfdf;
          > .card-info-dis {
            flex: 1;
            line-height: 30px;
            display: flex;
            flex-direction: column;
            color: #393939;
          }
        }
        > .user-card-item {
          width: 100%;
          height: 100%;
          border-radius: 3px;
          display: flex;
          padding: 5px 10px;
          > .user-card-item-left {
            min-width: 100px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            span:nth-child(1) {
              font-size: 15px;
              font-weight: 560;
              color: #2B2C36;
            }
            .user-card-item-right-top {
              margin-top: 5px;
              span {
                font-size: 14px;
                color: #2B2C36;
              }
            }
          }
          >.user-card-item-right {
            flex: 1;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }
  }
}