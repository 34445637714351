.map-con {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  > .map-info {
    width: 100%;
    height: 230px;
    background-color: #deafaf;
  }
  > .map-icon {
    width: 96%;
    height: 120px;
    border-radius: 12px;
    background-color: #ffffff;
    margin: 10px auto;
  }
}