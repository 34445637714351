.point-con {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  > .point-search {
    width: 100%;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > .top-banner {
      width: 100%;
      height: 170px;
      background-color: #fff;
    }
    > .top-info {
      width: 100%;
      height: auto;
      margin-top: 10px;
      padding: 10px 10px;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      > .person-info {
        width: 100%;
        height: 62px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-info{
          display: flex;
          align-items: center;
          .person-info-img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #ed7366;
          }
          .nick-info {
            font-size: 15px;
            margin-left: 10px;
          }
        }
      }
      > .info-fun {
        width: 100%;
        display: flex;
        font-size: 18px;
        font-weight: 500;
        padding: 0 16px;
        margin-top: 10px;
        justify-content: space-between;
        span:nth-child(2) {
          font-size: 12px;
          margin-left: 4px;
        }
      }
    }
  }
  > .point-filter {
    width: 100%;
    height: 100px;
    background: #fff;
    margin-top: 10px;
  }
  > .point-list {
    width: 100%;
    height: 200px;
    background: #fff;
    margin-top: 10px;
  }
}