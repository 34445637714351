html, body {
  touch-action: pan-y;
}

.home-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner-info {
    width: 100%;
    height: 180px;
    background-color: #4a4a4a;
  }

  .home-info {
    width: 96%;
    height: auto;
    background-color: #fff;
    border-radius: 9px;
    margin-top: -30px;
    z-index: 1000;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    > .home-info-name {
      width: 100%;
      display: flex;
      justify-content: space-between;
      > .home-info-header {
        display: flex;
        > .home-info-img {
          width: 50px;
          height: 50px;
          background: #4a4a4a;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        > .home-store-name {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          justify-content: space-between;
          span:nth-child(1) {
            font-size: 14px;
            font-weight: bold;
          }
          span:nth-child(2) {
            font-size: 12px;
            font-weight: 400;
            color: #999;
          }
        }
      }
      > .home-ercode {
        //margin-top: 12px;
        height: 29px;
        line-height: 29px;
        background: #ffffff;
        border-radius: 18px;
        padding: 0 15px;
        color: #cfc1a5;
        border: 1px solid #cfc1a5;
        letter-spacing: 2px;
        > .home-ercode-icon {
          font-size: 12px;
          margin-right: 5px;
        }
      }
    }
    .home-map {
      //position: absolute;
      font-size: 26px;
      margin-top: 8px;
      //right: 10px;
    }
  }
  .home-info-bottom {
    width: 100%;
    display: flex;
    margin-top: 10px;
    justify-content: space-around;
    > .info-bottom-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
    }
  }
}

.home-center {
  width: 96%;
  height: auto;
  display: flex;
  padding: 10px 0;
  background-color: #8f4848;
  margin: 10px auto;
  justify-content: space-between;
  > .home-center-left {
    width: 50%;
    height: 180px;
    background: #FFF5EB;
  }
  > .home-center-right {
    @extend .home-center-left;
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.add-info {
  width: 96%;
  border-radius: 30px;
  margin: 20px auto;
}
